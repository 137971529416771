<template>
  <div class="agreememo">
    <h1>《用户数据采集安全协议说明》</h1>
    {{agreememo}}
  </div>
</template>

<script>
export default {
  props: {
    // 内容
    agreememo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // agreememo: sessionStorage.getItem('agreememo') || ''
    }
  }
}
</script>

<style  scoped lang="less">
.agreememo {
  font-size: 30px;
  padding: 20px;
  white-space: pre-line;
  height: calc( 100% - 40px);
  overflow: auto;
  h1 {
    font-size: 40px;
    text-align: center;
  }
}
</style>