<template>
  <!-- scanEntry >>> 身份验证 by lbj-->
  <div class="page">
    <div v-show="loading" class="loading">
      <van-loading type="spinner" color="#1989fa" />
    </div>
    <div class="register_title">手机号码验证</div>
    <div class="input_box">
      <div class="icon">
        <!-- <img src="src/assets/img/login.png"
        alt="">-->
        <!-- <svg-icon icon-class="login"></svg-icon> -->
      </div>
      <div class="input_box_xia" v-show="mode">
        <!-- <el-input v-model="tel"
                  type="tel"
        placeholder="手机号码" />-->
        <van-field v-model="tel" type="tel" label="手机号" placeholder="手机号码" :error-message="errMsg" />
      </div>
      <div class="input_box_xia" v-show="mode">
        <!-- <el-input v-model="code"
                  type="tel"
                  placeholder="验证码" />
        <div class="code_gain"
             v-if="!showGetCode || countDown !== 0">{{ countDown === 0 ? "获取验证码" : `${countDown}秒重新获取` }}</div>
        <div class="code_gain code_gain-on bton"
             v-if="showGetCode && countDown === 0"
        @click="getCode">获取验证码</div>-->
        <van-field v-model="code" center clearable type="tel" label="短信验证码" placeholder="请输入验证码">
          <template #button>
            <van-button
              size="small"
              type="primary"
              disabled
              v-if="!showGetCode || countDown !== 0"
            >{{ countDown === 0 ? "获取验证码" : `${countDown}秒重新获取` }}</van-button>
            <van-button
              size="small"
              type="primary"
              v-if="showGetCode && countDown === 0"
              @click="getCode"
            >获取验证码</van-button>
          </template>
        </van-field>
      </div>
      <div class="input_box_xia" v-show="!mode">
        <!-- <el-input v-model="sfz"
                  type="tel"
        placeholder="身份证号码" />-->
        <van-field v-model="sfz" type="number" placeholder="身份证号码" />
      </div>
    </div>
    <div class="dragVerify" v-if="UseSlideCheck == 1">
      <drag-verify
        ref="dragVerify"
        class="drag"
        :width="drag_width"
        :height="drag_height"
        :isPassing.sync="isPassing2"
        text="请按住滑块拖动"
        successText="验证通过"
        handlerIcon="fa fa-angle-double-right"
        successIcon="fa fa-check"
        @passcallback="pass"
      >
        <i v-show="!isPassing2" slot="textBefore" class="fa fa-lock"></i>
      </drag-verify>
    </div>

    <div :class="`Sed bton ${filled && agree ? 'op' : ''}`" @click="check">确认</div>
    <div class="footer2" v-show="useagree">
      <van-checkbox v-model="agree" icon-size="20px" label-disabled>
        我已阅读并同意
        <span @click="showag=true">《用户数据采集安全协议说明》</span>
      </van-checkbox>
      <van-popup round :closeable="true" v-model="showag">
        <showAgreeMemo :agreememo="agreememo" />
      </van-popup>
    </div>
    <div class="footer" @click="changeMode" id="footer" v-show="showFooter">
      <span>{{ mode ? "切换身份证登陆" : "切换手机号登陆" }}</span>
    </div>
    <!-- 保存成功后提示板 -->
    <van-popup
      v-model="showPop"
      class="success_pop"
      position="right"
      :style="{ height: '100%', width: '100%' }"
    >
      <div class="contant">
        <div class="suc_header">提交成功</div>
        <div class="suc_image">
          <img src="@/assets/img/resumeSuc.png" alt />
        </div>
        <div class="suc_name">
          <!-- <span>{{empName}}</span> -->
          <span>您好！</span>
        </div>
        <div class="wuyong">你的手机绑定成功！</div>
        <div class="footer_btn">
          <!-- <div class="result_btn">
            <van-button color="#2B8DF0" @click="showResult">查看结果</van-button>
          </div>-->
          <div class="colser_btn">
            <van-button color="#2B8DF0" @click="colsePage">关闭</van-button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Notify } from 'vant'
import {
  getWxApiToken,
  getOpenId as gop,
  bindWxOpenIdByMobilePhone
} from '@api/wx.js'
import { getSmsCode } from '@api/login.js'
import { getOpenId, setOpenId, setYzjOpenId } from '@/utils/auth'
import { IdCodeValid } from '@/utils/index.js'
import showAgreeMemo from './showAgreeMemo'
import dragVerify from '@components/dragVerify/index.vue'
export default {
  components: {
    dragVerify,
    showAgreeMemo
  },
  data() {
    return {
      radio: null,
      screenHeight: document.documentElement.clientHeight, //屏幕高度
      h: document.documentElement.clientHeight,
      showFooter: false,
      timer: null,
      sfz: '',
      mode: true,
      isPassing2: false,
      filled: false,
      tel: '',
      code: '',
      showGetCode: false, // 显示获取验证码
      countDown: 0, // 验证码倒计时
      key: '', // 验证码绑定的key
      tj_empid: this.$route.query.tj_empid || '',
      module: this.$route.query.module || '240',
      restype: this.$route.query.restype || '',
      zpacid: this.$route.query.zpacid || '',
      loading: false,
      UseSlideCheck: window.UseSlideCheck || 1, //是否启用滑动验证：1表示启用,0表示不启用
      drag_width: 300,
      drag_height: 45,
      errMsg: '', // 手机号验证错误消息
      seStaList: ['304', '317', '319'],
      agree: true, // 是否同意《用户数据采集安全协议说明》
      useagree: false,
      agreememo: '',
      showag: false,
      stationid: this.$route.query.stationid || '',
      checkEmpIdCardInfo: false, // 6.	扫码登录后弹出个人信息确认窗,体针对用身份证号码登录的模式（不限模块号）
      showPersonalInfo: false, // 身份证登录时，是否弹出个人信息确认弹窗
      IDCard: '',
      perName: '',
      perSex: '',
      result: {},
      showPop: false // 绑定成功提示
    }
  },
  computed: {
    all() {
      const { tel, code, isPassing2, mode, sfz } = this
      return { tel, code, isPassing2, mode, sfz }
    }
  },

  watch: {
    tel(n) {
      if (/^1[3456789]\d{9}$/.test(n) || n == '') {
        this.errMsg = ''
      } else {
        this.errMsg = '手机号格式错误'
      }
    },
    all: {
      handler(n) {
        // 电话号码验证
        this.showGetCode = this.telTest(this.tel)
        if (this.mode) {
          //手机号码登录
          if (
            this.tel !== '' &&
            this.code !== '' &&
            ((this.UseSlideCheck == 1 && this.isPassing2) ||
              (this.UseSlideCheck == 0 && !this.isPassing2))
          ) {
            this.filled = true
          } else {
            this.filled = false
          }
        } // 身份证登录
        else {
          if (
            this.sfz !== '' &&
            ((this.UseSlideCheck == 1 && this.isPassing2) ||
              (this.UseSlideCheck == 0 && !this.isPassing2))
          ) {
            this.filled = true
          } else {
            this.filled = false
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    var _this = this
    window.onresize = function() {
      // 定义窗口大小变更通知事件
      _this.screenHeight = document.documentElement.clientHeight //窗口高度
    }
  },
  created() {
    // 判断各模块的登录控制
    getWxApiToken().then(res => {
      sessionStorage.setItem('token', res)
    })
    this.drag_width = window.screen.width * 0.84
    this.drag_height = window.screen.width * 0.12533
  },
  methods: {
    cancelPerDia() {
      this.$router.go(0)
    },
    changeMode() {
      this.mode = !this.mode
      this.isPassing2 = false
      this.$refs.dragVerify.reset()
      clearInterval(this.timer)
      this.countDown = 0
    },
    // 通过
    pass() {
      // console.log(this.isPassing2, "isPassing2");
    },
    // 电话号码验证
    telTest(tel) {
      return /^1[3456789]\d{9}$/.test(tel)
    },

    // 获取验证码
    getCode() {
      getSmsCode({
        mobilephone: this.tel
      }).then(res => {
        if (res.iserror == 0) {
          Notify({ type: 'success', message: '发送验证码成功' })
          this.code = ''
          this.key = res.topvalue
          if (res.topvalue == 1234) {
            this.code = 1234
          }
        } else if (res.iserror == 1) {
          Notify({
            type: 'danger',
            message: '获取验证码失败！原因：' + res.errormsg
          })
        }
      })
      this.countDown = 59
      this.timer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    // 确定
    check() {
      if (!this.filled) return
      if (this.mode) {
        // 手机号码登录
        // 验证码输入不一致
        if (this.code != this.key) {
          Notify({ type: 'danger', message: '验证码输入有误' })
          return
        }
        if (!this.agree) {
          Notify({
            type: 'danger',
            message: '请阅读并同意《用户数据采集安全协议说明》'
          })
          return
        }
        this.loading = true
        //return
        this.$nextTick(() => {
          setTimeout(() => {
            let hasOpenId = getOpenId()
            bindWxOpenIdByMobilePhone({
              mobilephone: this.tel,
              openid: hasOpenId || ''
            }).then(res => {
              this.showPop = true
            })
          }, 100)
        })
      }
    },
    showAgreeMemo() {
      sessionStorage.setItem('agreememo', this.agreememo)
      this.$router.push('/showAgreeMemo')
    },
    // 查看结果
    showResult() {
      this.showPop = false
    },
    // 保存成功后关闭按钮
    colsePage() {
      window.close()
      WeixinJSBridge.invoke('closeWindow', {}, function(res) {})
    }
  }
}
</script>

<style scoped lang="less">
.PerInfoConfirmDia {
  /deep/.van-dialog__header {
    padding-top: 0;
    height: 110px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 500;
    font-size: 40px;
  }
  .van-dialog__content {
    .van-cell-group {
      padding: 30px;
    }
  }
}
.page {
  height: 100vh;
  overflow-y: scroll;
  .loading {
    position: absolute;
    padding-top: 75%;
    padding-left: 45%;
    z-index: 1111;
  }
  .footer {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    span {
      color: red;
      font-size: 30px;
    }
  }
  .footer2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    .van-checkbox {
      font-size: 30px;
      span {
        color: red;
      }
    }
    .van-popup {
      width: 80%;
      height: 80%;
      overflow: auto;
      padding: 80px 20px 20px;
    }
  }
  .dragVerify {
    padding-top: 32px;
    padding-bottom: 16px;
    display: flex;
    justify-content: center;
    .drag {
      --width: 134px !important;
      --pwidth: -134px !important;
      // background: red!important;
      /deep/.dv_text {
        width: 100%;
        color: red;
      }
    }
    .drag_verify {
      /deep/.dv_handler {
        display: flex;
        justify-content: center;
        align-items: center;
        .fa {
          margin-top: unset;
        }
      }
    }
  }
  .register_title {
    font-size: 5vw;
    color: #303133;
    padding: 2vw 0;
    text-align: center;
    // border-bottom: 0.013333rem solid #eee;
    border-bottom: 0.13333vw solid #bababc;
  }
  .op {
    opacity: 1 !important;
  }
  .Sed {
    width: 84vw;
    height: 12.533vw;
    /* margin: 0 auto; */
    margin: 5vw auto;
    background-color: #247dff;
    border-radius: 0.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4.8vw;
    letter-spacing: 0.267vw;
    color: #ffffff;
    opacity: 0.4;
  }
  .input_box {
    width: 84vw;
    margin: 0 auto;
    margin-top: 13.333vw;
    .icon {
      width: 106px;
      height: 106px;
      margin: 0 auto;
      background: url('~@/assets/img/login.png') no-repeat;
      background-size: 100% 100%;
      // text-align: center;
      // .svg-icon {
      //   width: 27%;
      //   height: 106px;
      // }
    }
    .input_box_xia {
      height: 11.333vw;
      // border-bottom: 1px solid #edeff2;
      border-bottom: 1px solid #70b6ff;
      margin-top: 8vw;
      display: flex;
      justify-content: space-between;
      .code_gain {
        width: 35vw;
        height: 8vw;
        background-color: #edeff2;
        border-radius: 0.5vw;
        font-size: 3.467vw;
        color: #909399;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .code_gain-on {
        background-color: #008eed;
        color: #ffffff;
      }

      /deep/.van-cell {
        .van-field__label {
          width: 5.2em;
        }
        .van-field__value {
          .van-field__error-message {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }
  }
  .success_pop {
    .contant {
      height: 100%;
      .suc_header {
        height: 90px;
        line-height: 90px;
        background-color: #2b8df0;
        color: #ffffff;
        font-size: 32px;
        font-weight: bold;
        text-align: center;
      }
      .suc_image {
        height: 50%;
        text-align: center;
        padding: 50px 0;
        img {
          margin-top: 40px;
        }
      }
      .suc_name {
        color: #333333;
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        :first-child {
          color: #2b8df0;
          margin-right: 20px;
        }
      }
      .wuyong {
        color: #333333;
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        padding: 30px 0;
      }
      .footer_btn {
        width: 100%;
        position: absolute;
        bottom: 40px;
        .result_btn {
          text-align: center;
          .van-button {
            height: 80px;
            width: 35%;
            border-radius: 12px;
            .van-button__content {
              font-size: 28px;
              font-weight: bold;
            }
          }
        }
        .colser_btn {
          padding: 20px 0;
          text-align: center;
          color: #999;
          font-size: 28px;
          font-weight: bold;
          span {
            padding: 20px 40px;
          }
        }
      }
    }
  }
}
</style>
